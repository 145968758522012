/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  compact,
  filter,
  find,
  flatMap,
  flow,
  keyBy,
  mapValues,
  reject
} from 'lodash'
import React from 'react'
import { Link, useNavigate } from 'react-router'
import styled from 'styled-components'

import AnimatedOutlet from '../../components/animated-outlet'
import ColumnsButton from '../../components/data-table/columns-button'
import ExportButton from '../../components/data-table/export-button'
import Header from '../../components/data-table/header'
import Pagination from '../../components/data-table/pagination'
import Pills from '../../components/data-table/pills'
import SearchBar from '../../components/data-table/search-bar'
import SortButton from '../../components/data-table/sort-button'
import { filtersOnDocList } from '../../components/feature-flags'
import GuideBox from '../../components/guide-box'
import { LoadingPage } from '../../components/loading'
import Spinner from '../../components/spinner'
import { GraphQLError as Error } from '../../components/system-error'
import { useTileTransition } from '../../components/tile-transition'
import { useDocumentTitle } from '../../components/use-document-title'
import { useIds } from '../../components/use-ids'
import { formbot } from '../../formbot'
import {
  FormbotContext,
  IdFormkeyMapContext
} from '../../formbot/engine/formbot-react/hooks'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import FiltersConfig from '../../formbot/filter-config'
import * as Icons from '../../icons'
import { useInitWorkflow } from '../../pages-runner/run/components/mutation.init-workflow'
import { Announcer } from '../../ui/a11y'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../ui/table'
import * as Empty from './components/empty'
import localizeLabel from './components/localize-label'
import Row from './components/row'
import ShareButton from './components/share-button'
import {
  gqlToParams,
  useLoadById,
  useQueryParams,
  useSetDefaults
} from './components/use-params'
import { QueryContextProvider } from './components/use-query-context'
import Views from './components/views'

export default function ListById () {
  useDocumentTitle('Documents')
  const [params, storageKey] = useLoadById()
  if (!params) return <LoadingPage />
  return <List defaultParams={params} storageKey={storageKey} />
}

function List ({ defaultParams, storageKey }) {
  const previousDataRef = React.useRef(null)
  const { appId, datasetId, routes } = useIds()
  const { finish } = useTileTransition()
  const queryParams = useQueryParams(defaultParams, storageKey)
  const q = getListPageQuery(appId, datasetId, queryParams.gqlParams)
  const { query, ...qParams } = q
  const gqlResult = useQuery(query, qParams)
  previousDataRef.current = gqlResult?.data?.app || previousDataRef.current
  const app = previousDataRef.current
  const switchingDatasets =
    app?.id !== appId || (datasetId && app?.dataset?.id !== datasetId)
  useSetDefaults(
    queryParams.updateParams,
    app?.dataset ? { ...app.dataset, limit: queryParams.gqlParams.limit } : null
  )
  React.useEffect(() => {
    if (app) finish()
  }, [app])
  if (!app || switchingDatasets) return <LoadingPage />
  if (gqlResult.error) return <Error error={gqlResult.error} />
  return (
    <QueryContextProvider query={q}>
      <AnimatedOutlet
        context={{
          app,
          reset: queryParams.reset,
          refetch: gqlResult.refetch
        }}
      />
      <FormbotContext.Provider value={formbot}>
        <IdFormkeyMapContext.Provider
          value={buildRepeatableColumnIdMap(app.dataset.form.gadgetIndexTypes)}
        >
          <ListInner
            queryParams={queryParams}
            gqlResult={gqlResult}
            app={app}
            appId={appId}
            datasetId={datasetId}
            routes={routes}
          />
        </IdFormkeyMapContext.Provider>
      </FormbotContext.Provider>
    </QueryContextProvider>
  )
}

function buildRepeatableColumnIdMap (gadgetIndexTypes) {
  const repeatableGadgets = filter(gadgetIndexTypes, g =>
    ['Repeater', 'Table'].includes(g?.gadgetType)
  )
  const repeatableColumnGadgets = flatMap(repeatableGadgets, g => {
    if (g.gadgetType === 'Table') {
      return (
        g.childrenTemplate?.map(g2 => ({
          id: `data.${g2.id}`,
          formKey: `data.${g.formKey}.*.${g2.formKey}`
        })) ?? []
      )
    }

    const children = []
    traverseTemplate({ children: g.childrenTemplate }, g2 => {
      children.push({
        id: `data.${g2.id}`,
        formKey: `data.${g.formKey}.data.*.data.${g2.formKey}`
      })
    })
    return children
  })
  return mapValues(keyBy(repeatableColumnGadgets, 'id'), 'formKey')
}

function ListInner ({ queryParams, gqlResult, app, appId, datasetId, routes }) {
  const { params, gqlParams, updateParams, reset } = queryParams
  const [queryInput, setQueryInput] = React.useState(params.query || '')
  const runUrl = routes.run()

  const isNoResults =
    app.dataset.documentConnection.totalCount === 0 &&
    !params.query &&
    (filtersOnDocList
      ? !params.filter?.operators?.length
      : !params.filters.length)

  if (isNoResults) {
    return (
      <Empty.NoResults
        formUrl={
          app.viewer.canSubmitDocuments && app.dataset.isPublished
            ? runUrl
            : null
        }
      />
    )
  }

  const setCustomView = updater => {
    updateParams(draft => {
      draft.viewId = 'CUSTOM'
      updater(draft)
    })
  }

  const clearSearch = () => {
    setQueryInput('')
    updateParams(draft => {
      draft.skip = 0
      draft.query = ''
      draft.viewId = 'CUSTOM'
      draft.filter = null
      draft.versionConfig = 'LATEST_VERSION'
    })
  }

  const filterTitle = !queryParams.gqlParams.fields?.operators?.length
    ? 'Filter'
    : `${queryParams.gqlParams.fields.operators?.length} Filters`

  return filtersOnDocList ? (
    <div className='flex h-full flex-col overflow-hidden'>
      <div className='sticky top-0 z-10 bg-white px-4 pb-2 pt-4'>
        <SearchAndActions
          queryInput={queryInput}
          setQueryInput={setQueryInput}
          updateParams={updateParams}
          reset={reset}
          clearSearch={clearSearch}
          params={params}
          gqlParams={gqlParams}
          app={app}
          filterTitle={filterTitle}
          gqlResult={gqlResult}
          routes={routes}
          appId={appId}
          datasetId={datasetId}
          setCustomView={setCustomView}
        />
      </div>

      <div className='min-h-0 flex-1 overflow-auto px-4'>
        <TableContent
          app={app}
          params={params}
          updateParams={updateParams}
          clearSearch={clearSearch}
          setCustomView={setCustomView}
        />
      </div>
    </div>
  ) : (
    <div className='p-4 text-sm'>
      <SearchAndActions
        queryInput={queryInput}
        setQueryInput={setQueryInput}
        updateParams={updateParams}
        reset={reset}
        clearSearch={clearSearch}
        params={params}
        gqlParams={gqlParams}
        app={app}
        filterTitle={filterTitle}
        gqlResult={gqlResult}
        routes={routes}
        appId={appId}
        datasetId={datasetId}
        setCustomView={setCustomView}
      />

      <Pills
        columns={params.columns}
        value={params.filters}
        remove={field => {
          updateParams(draft => {
            draft.filters = reject(draft.filters, { field })
            draft.viewId = 'CUSTOM'
          })
        }}
      />

      <TableContent
        app={app}
        params={params}
        updateParams={updateParams}
        clearSearch={clearSearch}
        setCustomView={setCustomView}
      />
    </div>
  )
}

function localizeLabels (columns) {
  return columns.map(column => {
    return {
      ...column,
      label: localizeLabel(column.id, column.label)
    }
  })
}

const SearchAndActions = ({
  queryInput,
  setQueryInput,
  updateParams,
  reset,
  clearSearch,
  params,
  gqlParams,
  app,
  filterTitle,
  gqlResult,
  routes,
  appId,
  datasetId,
  setCustomView
}) => {
  const initWorkflow = useInitWorkflow()
  const navigate = useNavigate()
  const [newAppLoading, setNewAppLoading] = React.useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false)

  const setToDefault = () => {
    clearSearch()
    reset()
  }

  const currentQuery = gqlParams.query || ''
  const handleSearch = () => {
    if (queryInput === currentQuery) return
    setCustomView(draft => {
      draft.skip = 0
      draft.query = queryInput
    })
  }

  return (
    <div className='mb-2 flex items-center max-[1270px]:block'>
      <SearchBar
        collapse={1270}
        value={queryInput}
        onChange={setQueryInput}
        reset={clearSearch}
        onBlur={handleSearch}
        onEnter={handleSearch}
      />
      <div className='flex flex-1 flex-wrap items-center justify-between gap-2'>
        <div className='flex flex-wrap items-center gap-2'>
          <SortButton
            schema={app.dataset.form.schema}
            columns={localizeLabels(params.columns)}
            value={params.sorts}
            update={setCustomView}
          />
          <ColumnsButton
            className='h-[500px] w-[470px] p-2'
            value={localizeLabels(params.columns)}
            update={setCustomView}
          />
          <div className='relative'>
            <Views
              gqlParams={gqlParams}
              myFilters={app.dataset.myFilters.edges}
              setFilter={(connection, id) => {
                setQueryInput(connection.query || '')
                updateParams(() =>
                  gqlToParams(app.dataset.form, {
                    ...connection,
                    limit: params.limit,
                    id
                  })
                )
              }}
              setToDefault={setToDefault}
              activeView={params.viewId || 'DEFAULT'}
              isAppAdmin={app.viewer.canManage}
            />
            {filtersOnDocList && (
              <GuideBox
                type='new'
                title={i18n._({
                  id: 'pagesbuilder.doclist.guide.title',
                  message: 'Advanced Reporting Options'
                })}
                message={i18n._({
                  id: 'pagesbuilder.doclist.guide.message',
                  message:
                    'Create detailed reports right from your document list! Filter results, customize columns, see admin-shared views, and save your own views for future use.'
                })}
                localStorageName='advanced-reporting-guide'
                arrowPosition='top'
                arrowOrientation='right'
                offset='right-12'
                expiration='2025-05-07T12:00:00.000Z'
              />
            )}
          </div>
          {filtersOnDocList && (
            <>
              <button
                className='kp-button-transparent kp-button-sm'
                onClick={() => setShowAdvancedSettings(true)}
              >
                <Icons.Filter className='mr-2 fill-current' /> {filterTitle}
              </button>
              <FiltersConfig
                isOpen={showAdvancedSettings}
                setIsOpen={setShowAdvancedSettings}
                purpose='doc-list'
                hasVersions={app?.dataset?.allowNewVersions}
                params={{
                  ...params,
                  versionConfig: params?.versionConfig ?? 'LATEST_VERSION'
                }}
                updateParams={setCustomView}
                schema={app.dataset.form.schema}
              />
            </>
          )}
          {gqlResult.loading && <Spinner size={15} />}
        </div>
        <div className='flex flex-wrap items-center gap-2'>
          <ExportButton
            appName={app.name}
            csvRoute={routes.documentsCsv()}
            params={gqlParams}
          />
          <ShareButton params={gqlParams} />
          {app.viewer.canManage && (
            <Link className='kp-button-transparent kp-button-sm' to='settings'>
              <Icons.Settings className='mr-2 fill-blue-500' />
              <Trans
                id='pagesbuilder.doclist.data.settings'
                message='Data Settings'
              />
            </Link>
          )}
          {app.viewer.canSubmitDocuments && (
            <button
              className='kp-button-solid'
              onClick={() => {
                setNewAppLoading(true)
                initWorkflow(appId, datasetId)
                  .then(actionId => navigate(`${actionId}/action`))
                  .catch(() => null)
                  .then(() => setNewAppLoading(false))
              }}
              disabled={newAppLoading}
            >
              {newAppLoading ? (
                <Spinner size='20' />
              ) : (
                <Trans id='pagesbuilder.doclist.new' message='New' />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const TableContent = ({
  app,
  params,
  updateParams,
  clearSearch,
  setCustomView
}) => {
  const visibleColumns = flow(
    a => compact(a),
    a => filter(a, 'visible')
  )(localizeLabels(params.columns))

  return (
    <div className={filtersOnDocList ? '' : 'relative'}>
      <Table
        className={filtersOnDocList ? 'w-full min-w-max border-collapse' : ''}
      >
        {filtersOnDocList ? (
          <TableHeader className='sticky top-0 z-[5] bg-white shadow-sm'>
            <TableRow>
              {visibleColumns.map(column => {
                const sort = find(params.sorts, { field: column.formKey })
                return (
                  <TableHeaderCell
                    key={column.formKey}
                    className='whitespace-nowrap'
                  >
                    <div className='flex h-9 items-center'>
                      {column.label}
                      {sort &&
                        (sort.ascending ? (
                          <Icons.KeyboardArrowDown className='ml-1 h-3 w-3 fill-medium-gray-500 dark:fill-medium-gray-300' />
                        ) : (
                          <KeyboardArrowUp className='ml-1 h-3 w-3 fill-medium-gray-500 dark:fill-medium-gray-300' />
                        ))}
                    </div>
                  </TableHeaderCell>
                )
              })}
            </TableRow>
          </TableHeader>
        ) : (
          <Header
            params={params}
            updateParams={updateParams}
            columns={visibleColumns}
          />
        )}
        <TableBody>
          {app.dataset.documentConnection.edges.map(({ node }) => (
            <Row
              key={node.id}
              appId={app.id}
              document={node}
              columns={visibleColumns}
              schema={app?.dataset?.form?.schema}
              allowNewVersions={app?.dataset?.allowNewVersions}
              className='h-9'
              rowHeader={false}
            />
          ))}
        </TableBody>
        {app.dataset.documentConnection.totalCount !== 0 && (
          <Pagination
            onUpdate={({ skip, limit }) => {
              updateParams(draft => {
                draft.skip = skip
                draft.limit = limit
              })
            }}
            total={app.dataset.documentConnection.totalCount}
            skip={params.skip}
            limit={params.limit}
            className={
              filtersOnDocList ? 'sticky bottom-0 bg-white shadow-sm' : ''
            }
          />
        )}
      </Table>
      <Announcer id='document-list-pagination'>
        {getPaginationString(
          app.dataset.documentConnection.totalCount,
          params.skip,
          params.limit
        )}
      </Announcer>
      {app.dataset.documentConnection.totalCount === 0 && (
        <Empty.NoMatching reset={clearSearch} />
      )}
    </div>
  )
}

const KeyboardArrowUp = styled(Icons.KeyboardArrowDown)`
  transform: rotate(180deg);
`

const getPaginationString = (count, start, limit) => {
  if (!count) {
    return `${i18n._({ id: 'pagesbuilder.doclist.no.matching', message: 'No matching search results' })}`
  }
  const end = Math.min(start + limit, count)
  return `${i18n._(
    {
      id: 'pagesbuilder.doclist.count.documents',
      message: 'Showing {start} to {end} documents'
    },
    {
      start: start + 1,
      end,
      count
    }
  )}`
}

const getListPageQuery = (
  appId,
  pageId,
  { skip, limit = 25, sort, query, fields, versionConfig }
) => ({
  variables: {
    appId,
    pageId,
    skip,
    limit,
    sort,
    query,
    fields,
    versionConfig
  },
  query: gql`
    query ListPageQuery(
      $appId: ID!
      $pageId: ID
      $skip: Int!
      $limit: Int!
      $sort: [String!]
      $query: String
      $fields: Operator
      $versionConfig: VersionConfig
    ) {
      app(id: $appId) {
        id
        name
        viewer {
          canSubmitDocuments
          canManage
        }
        dataset(id: $pageId) {
          id
          isPublished
          allowNewVersions
          myFilters(args: { limit: 100 }) {
            edges {
              node {
                id
                name
                adminView
                connection {
                  sort
                  fields
                  query
                  columns
                  versionConfig
                }
              }
            }
          }
          documentListConfig {
            columns
            sort
          }
          form: formContainer {
            id
            gadgetIndexTypes
            schema {
              id
              formKey
              type
              label
              details
            }
          }
          documentConnection(
            args: {
              skip: $skip
              limit: $limit
              sort: $sort
              query: $query
              fields: $fields
              versionConfig: $versionConfig
            }
            keyBy: ID
          ) {
            totalCount
            edges {
              node {
                id
                viewer {
                  canEdit
                  canDelete
                  actions {
                    id
                    details
                    type
                  }
                }
                data
                meta
                integration
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              skip
              limit
            }
          }
        }
      }
    }
  `
})
